import request from '@/utils/request'
import SERVER from './server'
import { thenResolve } from '@/utils/index'

// 翻页
export const orderReviewPage = (params={}) =>request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderReview/query-leads`,params).then(thenResolve)

// 上传定金协议
export const uploadOrderProtocol = (params={}) =>request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderReview/upload-protocol`,params).then(thenResolve)

// 详情
export const orderReviewDetail = (params={}) =>request.get(`${SERVER.ORDER_SERVICE}/api/v1/orderReview/detail`,{ params }).then(thenResolve)

// 审批
export const orderReview = (params={}) =>request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderReview/review`,params).then(thenResolve)

// 上传线下合同
export const uploadOrderContract = (params={}) =>request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderReview/upload-contract`,params).then(thenResolve)

// 催办
export const uploadContractRemind = (params={}) =>request.post(`${SERVER.ORDER_SERVICE}/api/v1/orderReview/remind`,params).then(thenResolve)

