<script>
import SearchBarNew from '@/components/search-bar-new'
import ItemView from './components/item.vue'
import List from '@/components/baseList/list.vue'
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'

import { orderReviewPage } from '@/services/offlineOrder'
import clueServices from '@/services/clueServices'
import ClueDistributionServices from '@/services/clueDistributionServices'

import { arrayFormat,debounce,isMobilePhone } from '@/utils/index.js'

export default {
  name: 'offlineOrder',
  components:{ SearchBarNew,ItemView,List,shortConsumerInfo },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'offlineOrder')
    })
  },
  beforeRouteLeave(to, from, next) {
    // 如果回到销售助手（店端/总部）、交付助手首页 清空过滤数据
    if (['/delivery-assistant','/salesAssistant','/sales-assistant-portal'].some((item) => { return to.path.includes(item) })) {
      this.$store.commit('app/deleteKeepAlive', 'offlineOrder')
    }
    next()
  },
  data() {
    return {
      searchStr:'',
      countNum:0
    }
  },
  activated() {
    this.onRefresh()
  },
  methods:{
    onInput(val){
      
    },
    search(val) {
      this.searchStr = val
      this.onRefresh()
    },
    onRefresh:debounce(function() {            
      this.$refs.list.onRefresh()
    },500),
    fetchApi(obj = {}) {
      return orderReviewPage({
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        searchStr: this.searchStr
      }).then(res=>{
        this.countNum = res?.page?.totalCount || 0
        if (this.searchStr && (!res.dataList || res.dataList.length === 0) && res?.page?.pageIndex === '1'){
          this.$dialog.confirm({
            title: this.$t('提示'),
            message: this.$t('未在店内查询到该客户'),
            showConfirmButton: isMobilePhone(this.searchStr)? true: false,
            confirmButtonColor: '#B9921A',
            confirmButtonText: this.$t('立即创建新线索'),
          }).then( () => {
            this.creatClue()
          })            
        } else if (res.dataList && res.dataList.length === 1){
          const { havePermission,state,isRecycle,followUserName } = res.dataList[0]
          if (havePermission===false && state !== '2000000' && !isRecycle){
            this.$dialog.confirm({
              title: this.$t('提示'),
              message: `【${followUserName}】${this.$t('正在跟进该客户，你无法发起线下下单')}`,
              showConfirmButton:false,
            })
            return {
              dataList:[],
              page:{
                pageCount: '0',
                pageIndex: '1',
                pageSize: '20',
                totalCount: '0'
              }
            }
          }
        }
        return res
      })
    },
    async creatClue() {
      const res = await clueServices.creatClue({
        userName: '线下下单',
        userMobile: this.searchStr,
        channelOneId:'1753208491511615490',
        channelOne:this.$t('自然进店'),
        channelTwoId:'1753210199448981506',
        channelTwo:this.$t('门店新建')
      })
      this.$toast(this.$t('新建成功'))
      setTimeout(() => {
        this.goDetail({ leadId: res.id })
      }, 1000)
    },
    goDetail(params={}){
      this.$router.replace({
        path: '/offlineOrder/detail',
        query: {
          ...params,
          from: 'offlineOrder'
        }
      }) 
    },
    // 领取
    onReceive(item) {
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: this.$t('确定领取该线索'),
        confirmButtonColor: '#B9921A',
        confirmButtonText: this.$t('立即领取'),
      }).then(() => {
        // 判断线索是否在公共池
        if (item.isRecycle===1){
          ClueDistributionServices.setReceiveRecycle({ id: item.id }).then(()=>{
            this.$toast(this.$t('领取成功'))
            setTimeout(() => {
              this.goDetail({ leadId: item.id })
            }, 500)
          })
        } else {
          clueServices.receiveClue({ id: item.id }).then(()=>{
            this.$toast(this.$t('领取成功'))
            setTimeout(() => {
              this.goDetail({ leadId: item.id })
            }, 500)
          })
        }        
      })
    },
  }
}
</script>
<template>
  <div>
    <search-bar-new :placeholder-text="$t('请输入电话号码完整搜索 / 或客户姓名')" :str="searchStr"
      :showScreen="false"
      class="search" @input="onInput"
      @search="search"
       >
    </search-bar-new>
    <div>
      <div class="totality">{{$t('共')}} {{ countNum }} {{$t('条数据')}}</div>
      <List ref="list" :fetchApi="fetchApi">
        <template v-slot:default="{ item, index }">
          <ItemView :dataSource="item" 
            @goDetail="(row)=>{
              goDetail({id: row.reviewVo.id})
            }"
            @onReceive="onReceive"
            @creat="(row)=>{
              goDetail({leadId: row.id})
            }"
          />
        </template>
      </List>      
    </div>
  </div>
</template>
<style lang="less" scoped>
.totality{
  padding: 10px 16px 0;
  font-size: 12px;
}

</style>
